import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import FilterOptions from './FilterOptions';
import '../style/EventForm.css';
import "../App.css"
import "../style/private/CalendarInput.css"
import "../style/private/CheckboxList.css"
import "../style/private/ErrorReportPopup.css"
import "../style/private/FilterOptions.css"
import "../style/private/Header.css"
import "../style/private/Historic.css"
import "../style/private/Loader.css"
import "../style/private/Logo.css"
import "../style/private/ScoringComponent.css"
import "../style/private/SMSSection.css"
import "../style/private/TextFormatter.css"
import "../style/private/ToggleSlider.css"
import "../style/private/Home.css"
import "../style/private/Panel.css"
import "../style/private/Update.css"

function EventForm() {
    const { eventId } = useParams();
    const navigate = useNavigate();
    const [eventData, setEventData] = useState(null);
    const [studentCivility, setStudentCivility] = useState('');
    const [studentFirstName, setStudentFirstName] = useState('');
    const [studentLastName, setStudentLastName] = useState('');
    const [studentEmail, setStudentEmail] = useState('');
    const [studentMobile, setStudentMobile] = useState('');
    const [studentBirthDate, setStudentBirthDate] = useState('');
    const [studentNationality, setStudentNationality] = useState('');
    const [studentAddress, setStudentAddress] = useState('');
    const [studentCity, setStudentCity] = useState('');
    const [studentDepartment, setStudentDepartment] = useState('');
    const [studentParentEmail, setStudentParentEmail] = useState('');
    const [studentCursus, setStudentCursus] = useState('');
    const [studentLastDiploma, setStudentLastDiploma] = useState('');
    const [formations, setFormations] = useState([]);
    const [selectedFormation, setSelectedFormation] = useState([]);

    useEffect(() => {
        const fetchFormations = async () => {
            try {
                const response = await axios.get('https://csmapi.up.csmrouen.info/get-formations');
                const sortedFormations = response.data.sort((a, b) => a.order - b.order);
                let transformedArray = sortedFormations.map(obj => ({
                    label: obj.name,
                    value: obj.name
                }));

                const additionalObjects = [
                    { value: 'Champs non renseignés', label: 'Champs non renseignés' },
                    { value: 'Champs renseignés', label: 'Champs renseignés' },
                    { value: '------------', label: '------------', isDisabled: true }
                ];

                transformedArray = [...additionalObjects, ...transformedArray];
                setFormations(transformedArray);
            } catch (error) {
                console.error('Error fetching formations:', error);
            }
        };
        fetchFormations();
    }, []);

    useEffect(() => {
        const fetchEventData = async () => {
            try {
                const response = await axios.get(`https://csmapi.up.csmrouen.info/get-event/${eventId}`);
                setEventData(response.data[0]);
            } catch (error) {
                console.error('Erreur lors de la récupération des données', error);
            }
        };
        fetchEventData();
    }, [eventId]);

    const handleCreateStudent = () => {
        const formatDate = (date) => {
            const year = date.getFullYear();
            const month = String(date.getMonth() + 1).padStart(2, '0');
            const day = String(date.getDate()).padStart(2, '0');
            const hours = String(date.getHours()).padStart(2, '0');
            const minutes = String(date.getMinutes()).padStart(2, '0');
            const seconds = String(date.getSeconds()).padStart(2, '0');
            return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
        };

        const formData = {
            civility: studentCivility,
            first_name: studentFirstName,
            last_name: studentLastName,
            email: studentEmail,
            mobile: studentMobile,
            date_of_birth: studentBirthDate,
            nationality: studentNationality,
            address1: studentAddress,
            city: studentCity,
            department: studentDepartment,
            parents_email: studentParentEmail,
            current_curriculum: studentCursus,
            desired_training: selectedFormation.value,
            last_diploma_validated: studentLastDiploma,
            statut: 'Prospect',
            creation_date: formatDate(new Date()), // Use the formatDate function here
            date_register: formatDate(new Date()), // Use the formatDate function here
            origin: eventData?.name
        };
        console.log(formData)
        fetch('https://csmapi.up.csmrouen.info/addStudents', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(formData),
        })
            .then(response => { response.json(); window.location.reload() })
            .catch(error => console.error('Erreur lors de la création de l\'étudiant:', error));
    };

    const handleFormationChange = (selectedOptions) => {
        setSelectedFormation(selectedOptions);
    };

    console.log(eventData?.name);

    return (
        <div className="event-form-container">
            <div className="event-back-container">
                <svg xmlns="http://www.w3.org/2000/svg" height='32' width='32' viewBox="0 0 448 512" onClick={() => navigate(-1)} className="back-arrow"><path d="M9.4 233.4c-12.5 12.5-12.5 32.8 0 45.3l160 160c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L109.2 288 416 288c17.7 0 32-14.3 32-32s-14.3-32-32-32l-306.7 0L214.6 118.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0l-160 160z"/></svg>
                <p className="event-p">Nouveau contact</p>
            </div>
            <div className="event-form-background-container"></div>
            <div className='event-form'>
                {eventData && eventData.name && (
                    <h1 className='event-form-title'>{eventData.name}</h1>
                )}
                {eventData && eventData.options && eventData.options.split(';').map((option, index) => (
                    <div key={index} className="event-form-group">
                        {option === 'Civilité' && (
                            <>
                                <label>{option}</label>
                                <select className="event-form-input" value={studentCivility} onChange={(e) => setStudentCivility(e.target.value)}>
                                    <option value="" disabled>Sélectionnez une civilité</option>
                                    <option value="Madame">Madame</option>
                                    <option value="Monsieur">Monsieur</option>
                                </select>
                            </>
                        )}
                        {option === 'Nom' && (
                            <>
                                <label>{option}</label>
                                <input className="event-form-input" type="text" value={studentLastName} onChange={(e) => setStudentLastName(e.target.value)} />
                            </>
                        )}
                        {option === 'Prénom' && (
                            <>
                                <label>{option}</label>
                                <input className="event-form-input" type="text" value={studentFirstName} onChange={(e) => setStudentFirstName(e.target.value)} />
                            </>
                        )}
                        {option === 'Email' && (
                            <>
                                <label>{option}</label>
                                <input className="event-form-input" type="text" value={studentEmail} onChange={(e) => setStudentEmail(e.target.value)} />
                            </>
                        )}
                        {option === 'Numéro de téléphone' && (
                            <>
                                <label>{option}</label>
                                <input className="event-form-input" type="text" value={studentMobile} onChange={(e) => setStudentMobile(e.target.value)} />
                            </>
                        )}
                        {option === 'Date de naissance' && (
                            <>
                                <label>{option}</label>
                                <input className="event-form-input" type="date" value={studentBirthDate} onChange={(e) => setStudentBirthDate(e.target.value)} />
                            </>
                        )}
                        {option === 'Nationalité' && (
                            <>
                                <label>{option}</label>
                                <input className="event-form-input" type="text" value={studentNationality} onChange={(e) => setStudentNationality(e.target.value)} />
                            </>
                        )}
                        {option === 'Adresse' && (
                            <>
                                <label>{option}</label>
                                <input className="event-form-input" type="text" value={studentAddress} onChange={(e) => setStudentAddress(e.target.value)} />
                            </>
                        )}
                        {option === 'Département' && (
                            <>
                                <label>{option}</label>
                                <input className="event-form-input" type="text" value={studentDepartment} onChange={(e) => setStudentDepartment(e.target.value)} />
                            </>
                        )}
                        {option === 'Ville' && (
                            <>
                                <label>{option}</label>
                                <input className="event-form-input" type="text" value={studentCity} onChange={(e) => setStudentCity(e.target.value)} />
                            </>
                        )}
                        {option === 'Contact parents' && (
                            <>
                                <label>{option}</label>
                                <input className="event-form-input" type="text" value={studentParentEmail} onChange={(e) => setStudentParentEmail(e.target.value)} />
                            </>
                        )}
                        {option === 'Cursus actuel' && (
                            <>
                                <label>{option}</label>
                                <input className="event-form-input" type="text" value={studentCursus} onChange={(e) => setStudentCursus(e.target.value)} />
                            </>
                        )}
                        {option === 'Formation souhaitée' && (
                            <>
                                <label>{option}</label>
                                <FilterOptions
                                    options={formations}
                                    placeholderOptions="Sélectionnez une formation"
                                    onChange={handleFormationChange}
                                    isClearable={true}
                                    isMultiOptions={false}
                                />
                            </>
                        )}
                        {option === 'Dernier diplôme' && (
                            <>
                                <label>{option}</label>
                                <input className="event-form-input" type="text" value={studentLastDiploma} onChange={(e) => setStudentLastDiploma(e.target.value)} />
                            </>
                        )}
                    </div>
                ))}
                <button className='event-form-validate' onClick={handleCreateStudent}>Valider l'inscription</button>
            </div>
        </div>
    );
}

export default EventForm;
