import React from 'react';
import Select from 'react-select';

import "../App.css"
import "../style/private/CalendarInput.css"
import "../style/private/CheckboxList.css"
import "../style/private/ErrorReportPopup.css"
import "../style/private/FilterOptions.css"
import "../style/private/Header.css"
import "../style/private/Historic.css"
import "../style/private/Loader.css"
import "../style/private/Logo.css"
import "../style/private/ScoringComponent.css"
import "../style/private/SMSSection.css"
import "../style/private/TextFormatter.css"
import "../style/private/ToggleSlider.css"

const FilterOptions = ({ options, placeholderOptions, isMultiOptions, onChange, onClick, defaultValue, defaultFiltersValue, closeMenu, isClearable, id }) => {
  const placeholder = placeholderOptions ? placeholderOptions : '...';
  const defaultOption = defaultValue
    ? defaultValue.split(', ').map((value) => ({ value, label: value.trim() }))
    : [];

  let defaultFilters = [];
  if (Array.isArray(defaultFiltersValue) && defaultFiltersValue.length > 0) {
    defaultFilters = defaultFiltersValue.map((value) => ({ value, label: value }));
  }

  const selectProps = {
    placeholder: placeholder,
    classNamePrefix: 'info',
    styles: {
      valueContainer: (baseStyles) => ({
        ...baseStyles,
        maxWidth: '100%',
        overflow: 'clip',
      }),
      container: (baseStyles) => ({
        ...baseStyles,
        height: 'auto',
        width: '100%',
        margin: '2px 0',
        zindex: '5',

      }),
      menu: (baseStyles) => ({
        ...baseStyles,
        textAlign: 'left',
      }),
      control: (baseStyles, state) => ({
        ...baseStyles,
        height: 'auto',
        width: '100%',
        zindex: '5',
        backgroundColor: 'transparent',
        border: 'none',
      }),
      multiValue: (baseStyles) => ({
        ...baseStyles,
        backgroundColor: 'rgb(233 233 233)',
        zindex: '5',

      }),
      multiValueLabel: (baseStyles) => ({
        ...baseStyles,
        color: '#248485',
        zindex: '5',
      }),
    },
    options: options,
    onChange: onChange,
    onClick: onClick,
    defaultValue: defaultOption.length > 0 ? defaultOption : defaultFilters,
    closeMenuOnSelect: !closeMenu ? closeMenu : true,
    isClearable: isClearable === true ? true : undefined,
    id: id,
  };

  if (isMultiOptions === undefined) {
    selectProps.isMulti = true;
  }

  return <Select {...selectProps} />;
};

export default FilterOptions;
